<template>
  <div ref="top" id="top"></div>
  <!-- publication and blogs overview -->
  <div id="master" v-if="(actuality != null)">
    <div class="block-title">Détail d'actualité</div>
    <div id="publication">
      <div id="title">
        {{ actuality.title }}
      </div>
      <div id="description">
        {{ actuality.intro }}
      </div>
      <div id="image">
        <img :src="actuImg(actuality.img)" alt="">
      </div>
      <!-- <div id="content">
        {{ actuality.content }}
      </div> -->
      <div id="content">
        <div class="item" v-for="content in actuality.content" v-bind:key="content">
          <div class="title">
            {{ content.id +"- "+ content.title +":" }}
          </div>
          <div class="paragraph">
            {{ content.paragraph }}
          </div>
        </div>
      </div>
      <div id="sub-actu">
        <div class="item" v-for="subActu in actuality.subActu" v-bind:key="subActu">
          <div class="title">
            {{ subActu.title }}
          </div>
          <div class="intro">
            {{ subActu.intro }}
          </div>
          <div class="content">
            <div class="item" v-for="subActuContentItem in subActu.content" v-bind:key="subActuContentItem">
              <div class="title">
                <font-awesome-icon :icon="['fas', 'arrow-right']" />
                {{ subActuContentItem.title }}
              </div>
              <div class="paragraph">
                {{ subActuContentItem.paragraph }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="resume">
        {{ actuality.resume }}
      </div>
      <div id="owner">
        <!-- <img :src="ownerImageUrl(actuality.owner.image)" alt=""> -->
        <img src="@/assets/blue_avatar.png" alt="">
        <div id="name">Admin</div>
        <div id="date">{{ actuality.createdAt }}</div>
      </div>
    </div>
    <div id="blogs-overview">
      <!-- recent blog -->
      <div id="recents-blog">
        <div class="sub-title">Publications récéntes</div>
        <div class="recent" v-for="recent in recents" v-bind:key="recent.id">
          <router-link :to="{name: 'iactuality', params: {id: recent.id}}">
            <div class="img-title">
              <!-- image -->
              <div class="img">
                <div class="back-img"></div>
                <img :src="actuImg(recent.img)" alt="">
              </div>
              <!-- title -->
              <div class="title">
                {{ recent.title }}
              </div>
            </div>
          </router-link>
          <!-- date and overview -->
          <div class="date-overview">
            <span class="date">
              <font-awesome-icon :icon="['fas', 'calendar-days']" />
              {{ recent.createdAt }}
            </span>
            <span class="owner">
              <font-awesome-icon :icon="['fas', 'user']" />
              Admin
            </span>
          </div>
        </div>
        <router-link :to="{name: 'iactualities'}" id="voir-plus">
          Voir plus
        </router-link>
      </div>
    </div>
  </div>
  <!-- loading -->
  <div class="loading" v-else>
    <font-awesome-icon :icon="['fas', 'circle-notch']" class="load-icon" />
  </div>
</template>

<script>

import axios from 'axios';
// import CommentForm from '@/components/ignite/CommentForm.vue';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { getActu, actusRecents } from '@/data/actualites.js';

export default {
  name: 'IBlogComponent',
  data() {
    return {
      actuality: null,
      commentFormVisible: false,
      comments: [],
    }
  },
  computed: {
    recents() {
      return actusRecents;
    }
  },
  methods: {
    fetchActuality() {
      axios.get(process.env.VUE_APP_API+"/publication/"+this.id)
      .then((response) => {
        this.actuality = response.data;
      })
      .then(() => {
        this.fetchRecentActualities();
      })
      .then(() => {
        this.fetchComments();
      })
      .catch((error) => {
        console.error(error);
      });
    },
    isCommentFormVisible() {
      return this.commentFormVisible;
    },
    showCommentForm() {
      this.commentFormVisible = true;
    },
    fetchComments() {
      axios.get(process.env.VUE_APP_API+"/comment/publication/"+this.actuality.id)
      .then((response) => {
        this.comments = [...response.data];
        console.log(response);
      })
      .catch((error) => {
        console.log("error", error);
      })
    },
    fetchRecentActualities() {
      axios.get(process.env.VUE_APP_API+"/publication/recents")
      .then((response) => {
        this.recents = [...response.data];
      })
      .catch((error) => {
        console.error(error);
      })
    },
    newCommentHandler() {
      this.commentFormVisible = false;
      this.fetchActuality();
    },
    formatDate(date) {
      return format(new Date(date), 'LLL dd, uuu à HH:mm', {locale: fr});
    },
    closeCommentForm() {
      this.commentFormVisible = false;
    },
    imageUrl(image) {
      return process.env.VUE_APP_URL +"/"+image;
    },
    ownerImageUrl(image) {
      if(image == '' || image == null) return require('@/assets/blue_avatar.png');
      return process.env.VUE_APP_URL+""+image;
    },
    scrollToTop() {
      const el = this.$refs.top;
      if(el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    },
    actuImg(img) {
      return require('@/assets/actus/'+img);
    },
  },
  components: {
    // CommentForm,
  },
  created() {
    // this.fetchActuality();
  },
  mounted() {
    this.actuality = getActu(this.$route.params.id);
    this.scrollToTop();
  }
}

</script>

<style lang="scss" scoped>
#top {
  margin-top: 50px;
}
#master {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 4rem 0;
  box-sizing: border-box;
  #publication {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    @media (min-width: 750px) {
      width: 70%;
      padding: 4rem;
    }
    #title {
      font-size: 2rem;
      color: #363636;
      font-weight: 400;
      text-align: start;
    }
    #description, #resume {
      margin-top: 1rem;
      line-height: 1.7rem;
      color: rgb(0, 0, 0);
      font-weight: 400;
      text-align: justify;
    }
    #image {
      margin-top: 1rem;
      width: 100%;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
      }
    }
    #content {
      margin-top: 1rem;
      font-weight: 300;
      color: #2b2b2b;
      line-height: 1.7rem;
      text-align: start;
      .item {
        margin-bottom: 1rem;
        .title {
          // color: #000;
          color: #00194c;
          font-weight: 500;
          font-size: 1.1rem;
        }
        .paragraph {
          text-align: justify;
        }
      }
    }
    #sub-actu {
      .item {
        margin-bottom: 1rem;
        text-align: start;
        .title {
          text-transform: uppercase;
          color: #000000;
          font-weight: 700;
        }
        .intro {
          margin-top: 0.3rem;
          line-height: 1.4rem;
          text-align: justify;
        }
        .content {
          margin-top: 0.5rem;
          .item {
            margin-bottom: 1rem;
            .title {
              font-weight: 500;
              text-transform: lowercase;
              color: #00194c;
            }
            .paragraph {
              margin-top: 0.4rem;
              line-height: 1.4rem;
              margin-left: 2rem;
              text-align: justify;
            }
          }
        }
      }
    }
    #owner {
      margin-left: auto;
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 5rem;
        height: 5rem;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
      }
      #name {
        margin-left: 1rem;
        margin-right: 1rem;
      }
      #date {
        text-transform: uppercase;
      }
    }
    #comments {
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 100%;
      box-sizing: border-box;
      #count {
        font-size: 1.3rem;
      }
      .comment {
        margin: 1rem 0;
        display: flex;
        flex-direction: row;
        .img {
          width: 5rem;
          img {
            // display: block;
            width: 3rem;
            height: 3rem;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
          }
        }
        .body {
          text-align: start;
          .name {
            font-weight: 600;
          }
          .date {
            margin-top: 1rem;
            text-transform: uppercase;
            color: #207dff;
            font-size: 0.9rem;
            font-weight: 300;
          }
          .content {
            margin-top: 1rem;
            font-size: 1rem;
            font-weight: 300;
          }
          .reply-btn {
            margin-top: 0.2rem;
            border: none;
            padding: 0.4rem 1rem;
            color: #000;
            border-radius: 0.2rem;
            transition: .4s ease all;
            &:hover {
              background-color: #000;
              color: #fff;
            }
          }
        }
      }
      .comment-btn {
        margin-top: 1rem;
        display: block;
        width: 100%;
        padding: 0.7rem 1.5rem;
        color: #fff;
        background-color: #207dff;
        border-radius: 0.2rem;
        border: none;
        font-size: 1.2rem;
        background-color: #207dff;
        transition: .4s ease all;
        &:hover {
          color: #207dff;
          border: 1px solid #207dff;
          background-color: #fff;
        }
      }
    }
  }
  #blogs-overview {
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    @media (min-width: 750px) {
      width: 30%;
      padding-top: 4rem;
      padding-right: 2rem;
    }
    #recents-blog {
      .recent {
        margin-top: 1rem;
        margin-bottom: 3rem;
        width: 100%;
        a {
          color: none;
          text-decoration: none;
        }
        .img-title {
          display: flex;
          align-items: start;
          width: 100%;
          .img {
            position: relative;
            width: 4.5rem;
            min-width: 4.5rem;
            height: 4.5rem;
            .back-img {
              position: absolute;
              z-index: 1;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: #ffffff31;
            }
            img {
              position: relative;
              z-index: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .title {
            padding: 0 1rem;
            text-align: start;
            height: 3.9rem;
            color: #000;
            line-height: 1.3rem;
            font-weight: 300;
            overflow: hidden;
            box-sizing: border-box;
            &:hover {
              color: #00194c;
            }
          }
        }
        .date-overview {
          padding-left: 5.5rem;
          padding-top: 1rem;
          text-align: end;
          width: 100%;
          box-sizing: border-box;
          text-transform: uppercase;
          color: rgb(148, 148, 148);
          font-size: 0.8rem;
          .owner {
            margin-left: 1rem;
          }
          .comments-count {
            margin-left: 1rem;
          }
        }
      }
      #voir-plus {
        display: block;
        padding: 0.7rem;
        color: #ffffff;
        border-radius: 0.2rem;
        background-color: #00194c;
        text-decoration: none;
        &:hover {
          background-color: #00194c;
        }
      }
    }
  }
}
.sub-title {
  font-size: 1.3rem;
  font-weight: 600;
  text-align: start;
}
.block-title {
  width: 80%;
  margin: 0 auto;
  font-size: 2rem;
  color: #00194c;
  font-weight: 400;
}
.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .load-icon {
    animation: loader linear 1s infinite;
    font-size: 3rem;
    transform-origin: center;
    color: #207dff;
  }
}
@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>