<template>
  <div ref="top"></div>
  <!-- WELCOME BLOCK -->
  <div id="welcome-block">
    <video id="background-video" autoplay muted loop playsinline>
      <source src="@/assets/short-background.mp4" type="video/mp4">
    </video>
    <div id="welcome">
      <div id="title">
        Bienvenue sur Nsi Mada
      </div>
      <!-- <div id="content" ref="content">
        {{ progressiveText }}
      </div> -->
      <welcome-slider-component />
      <!-- <div class="content" v-if="current(0)">
        NSI Madavous accompagne dans vos démarches d’externalisation dans la réalisation de vos projets à court, à moyen et à long terme.
      </div>
      <div class="content" v-show="() => {return (currentIndex == 1)}">
        Composée de jeunes passionnés, nos collaborateurs interviennent dans plusieurs domaines, multitâches et disposant des années d’expériences.
      </div>
      <div class="content" v-show="() => {return (currentIndex == 2)}">
        Les managers vous accompagnent avec des équipes proactives, répondant aux attentes des clients dans la réalisation de leurs objectifs.
      </div> -->
      <!-- <a href="" id="contact-us-btn">
          Contactez-nous
      </a> -->
      <router-link :to="{name: 'icontact'}" id="contact-us-btn">Contactez-nous</router-link>
    </div>
  </div>
  <!-- stat block -->
  <div id="stat" @scroll="handleStatScroll">
    <div class="item">
      <div class="chiffre">{{ count.years }}</div>
      <div class="title">Ans d'expérience</div>
    </div>
    <div class="item">
      <div class="chiffre">{{ count.collabs }}+</div>
      <div class="title">Collaboration</div>
    </div>
    <div class="item">
      <div class="chiffre">{{ count.projects }}+</div>
      <div class="title">Projet traités</div>
    </div>
  </div>
  <!-- WHY US BLOCK -->
  <div id="why-us">
    <div class="block-title">Pourquoi nous choisir</div>
    <div id="cards">
      <div class="card">
        <div class="icon">
          <font-awesome-icon :icon="['fas', 'arrows-up-down-left-right']" />
        </div>
        <div class="title">Polyvalence</div>
        <div class="content">
          Nous comprenons que chaque entreprise a des besoins uniques. C'est pourquoi nous offrons une gamme de services polyvalents qui peuvent être adaptés à vos exigences spécifiques. De la gestion des appels à la gestion de la chaîne d'approvisionnement, nous couvrons une multitude de domaines
        </div>
      </div>
      <div class="card">
        <div class="icon">
          <font-awesome-icon :icon="['fas', 'shield']" />
        </div>
        <div class="title">Confidentialité et Sécurité des Données</div>
        <div class="content">
          Nous traitons vos données sensibles avec la plus grande confidentialité et nous nous conformons aux normes de sécurité les plus strictes pour garantir la protection de vos informations.
        </div>
      </div>
      <div class="card">
        <div class="icon">
          <font-awesome-icon :icon="['fas', 'shuffle']" />
        </div>
        <div class="title">Flexibilité et Réactivité </div>
        <div class="content">
          Nous sommes flexibles pour nous adapter à vos besoins en constante évolution. Notre équipe est également réactive, prête à répondre rapidement à toutes vos demandes.
        </div>
      </div>
      <div class="card">
        <div class="icon">
          <font-awesome-icon :icon="['far', 'chart-bar']" />
        </div>
        <div class="title">Résultats Mesurables </div>
        <div class="content">
          Nous nous efforçons de fournir des résultats mesurables qui ajoutent de la valeur à votre entreprise. Notre suivi rigoureux nous permet de suivre et d'analyser l'impact de nos actions.
        </div>
      </div>
      <div class="card">
        <div class="icon">
          <font-awesome-icon :icon="['fas', 'handshake-simple']" />
        </div>
        <div class="title">Partenariat à Long Terme </div>
        <div class="content">
          Nous considérons nos clients comme des partenaires à long terme. Nous cherchons à établir des relations solides et durables basées sur la confiance et la réussite mutuelle.
        </div>
      </div>
      <div class="card">
        <div class="icon">
          <font-awesome-icon :icon="['fas', 'calendar-days']" />
        </div>
        <div class="title">Depuis plus de 13 ans de compétences inégalées</div>
        <div class="content">
          Notre équipe est composée de professionnels hautement qualifiés dans divers domaines. De la gestion administrative à la gestion de projet, nous possédons une vaste expérience pour répondre à vos besoins.
        </div>
      </div>
      <div class="card">
        <div class="icon">
          <font-awesome-icon :icon="['fas', 'check-to-slot']" />
        </div>
        <div class="title">Engagement envers la Qualité </div>
        <div class="content">
          Notre priorité absolue est la qualité du service que nous fournissons. Nous nous engageons à atteindre et à maintenir des normes de qualité élevées dans toutes nos tâches et projets.
        </div>
      </div>
    </div>
  </div>
    <!-- NOS VALEURS -->
  <div id="nos-valeurs-block">
    <div class="block-title">Nos Valeurs</div>
    <div id="nos-valeurs">
      <div id="image-valeurs">
        <img src="@/assets/iob.jpg" alt="">
      </div>
      <div id="content-valeurs">
        <div class="val">
          <span class="title">
            NSI Mada
          </span>
          <span class="content">
            a pour mission de simplifier la sous-traitance pour nos clients. Nous comprenons que chaque entreprise a des besoins uniques, c'est pourquoi nous collaborons étroitement avec nos clients pour concevoir des solutions sur mesure qui répondent à leurs besoins spécifiques. Notre objectif ultime est de contribuer à la croissance et au succès de nos clients en leur offrant des services de sous-traitance fiables et rentables
          </span>
        </div>
        <div class="val">
          <span class="title">Éthique professionnelle : </span>
          <span class="content">L'éthique professionnelle est fondamentale pour nous. Nous traitons nos clients, nos employés et nos partenaires avec le plus grand respect, respectons les engagements contractuels, et protégeons la confidentialité des données.</span>
        </div>
        <div class="val">
          <span class="title">Gestion de Projet :</span>
          <div class="content">La gestion de projet est l'une de nos compétences centrales. Nous sommes capables de planifier, exécuter et livrer des projets dans les délais et le budget convenus</div>
        </div>
      </div>
    </div>
    <div id="portefolio">
      <a href="/nsiPfl.pdf" target="_blank">
        <div id="pf-button">
          Téléchargez notre portefolio
        </div>
      </a>
    </div>
  </div>
  <!-- INFRASTRUCTURE -->
  <div id="infrastructure-block">
    <div id="title" class="block-title">
      Notre infrastructure
    </div>
    <div id="infrastructure">
      <div class="card">
        <div class="icon"><font-awesome-icon :icon="['fas', 'arrows-to-dot']" /></div>
        <div class="title">Centre d'Opérations Centralisé</div>
        <div class="content">Notre infrastructure est basée sur un centre d'opérations centralisé, doté de capacités de gestion de projet et de communication de pointe. Cela nous permet de coordonner efficacement toutes les tâches de sous-traitance, quelle que soit leur complexité.</div>
      </div>
      <div class="card">
        <div class="icon"><font-awesome-icon :icon="['fas', 'bars-progress']" /></div>
        <div class="title">Systèmes de Gestion Intégrés</div>
        <div class="content">Nous avons mis en place des systèmes de gestion intégrés qui nous permettent de surveiller et de gérer toutes les activités de manière transparente. Cela comprend la gestion des tâches, la planification des ressources, et le suivi des performances.</div>
      </div>
      <div class="card">
        <div class="icon"><font-awesome-icon :icon="['fas', 'microchip']" /></div>
        <div class="title">Plateformes Technologiques Avancées</div>
        <div class="content">Notre société investit constamment dans des technologies de pointe pour automatiser des processus, améliorer l'efficacité et garantir une communication fluide entre toutes les parties prenantes.</div>
      </div>
      <div class="card">
        <div class="icon"><font-awesome-icon :icon="['fas', 'shield']" /></div>
        <div class="title">Cybersécurité de Haut Niveau</div>
        <div class="content">La sécurité des données est notre priorité. Nous utilisons des solutions de cybersécurité de pointe pour protéger les informations sensibles et garantir la confidentialité de vos données.</div>
      </div>
      <div class="card">
        <div class="icon"><font-awesome-icon :icon="['fas', 'people-group']" /></div>
        <div class="title">Équipe Expertisée en Technologie </div>
        <div class="content">Notre personnel est composé d'experts en technologie de l'information, en développement logiciel et en gestion de projets. Ils sont en mesure de personnaliser nos solutions technologiques pour répondre à vos besoins spécifiques.</div>
      </div>
      <div class="card">
        <div class="icon"><font-awesome-icon :icon="['fas', 'puzzle-piece']" /></div>
        <div class="title">Évolutivité et Flexibilité</div>
        <div class="content">Notre infrastructure est conçue pour être hautement évolutive, ce qui nous permet de nous adapter rapidement à vos besoins changeants en termes de volume de travail et de services.</div>
      </div>
      <div class="card">
        <div class="icon"><font-awesome-icon :icon="['fas', 'weight-scale']" /></div>
        <div class="title">Suivi et Analyse des Performances</div>
        <div class="content">Nous utilisons des outils d'analyse avancés pour surveiller les performances en temps réel, ce qui nous permet d'optimiser nos processus et d'obtenir des résultats mesurables.</div>
      </div>
      <div class="card">
        <div class="icon"><font-awesome-icon :icon="['fas', 'arrows-to-circle']" /></div>
        <div class="title">Collaboration Transparente</div>
        <div class="content">Grâce à nos technologies de communication avancées, nous offrons une collaboration transparente avec nos clients, ce qui garantit une compréhension mutuelle et une gestion efficace des projets.</div>
      </div>
      <!--
        <div class="card">
          <div class="icon"></div>
          <div class="title">Équipe Expertisée en Technologie</div>
          <div class="content">Notre personnel est composé d'experts en technologie de l'information, en développement logiciel et en gestion de projets. Ils sont en mesure de personnaliser nos solutions technologiques pour répondre à vos besoins spécifiques.</div>
        </div>
        <div class="card">
          <div class="icon"></div>
          <div class="title">Évolutivité et Flexibilité</div>
          <div class="content">Notre infrastructure est conçue pour être hautement évolutive, ce qui nous permet de nous adapter rapidement à vos besoins changeants en termes de volume de travail et de services.</div>
        </div>
        <div class="card">
          <div class="icon"></div>
          <div class="title">Suivi et Analyse des Performances </div>
          <div class="content">Nous utilisons des outils d'analyse avancés pour surveiller les performances en temps réel, ce qui nous permet d'optimiser nos processus et d'obtenir des résultats mesurables.</div>
        </div>
        <div class="card">
          <div class="icon"></div>
          <div class="title">Collaboration Transparente</div>
          <div class="content">Grâce à nos technologies de communication avancées, nous offrons une collaboration transparente avec nos clients, ce qui garantit une compréhension mutuelle et une gestion efficace des projets.</div>
        </div>
      -->
    </div>
  </div>
  <!-- SATISFIED -->
  <div id="satisfied">
    <partenariat-slider></partenariat-slider>
  </div>
  <!-- recent blog -->
  <div id="blog-recent-block">
    <div class="block-title">Actualités récentes</div>
    <div id="blog-recent">
      <div class="blog" v-for="recent in recents" v-bind:key="recent.id" @click="() => {actuLinkHandler(recent.id)}">
        <div class="image">
          <div class="img-back"></div>
          <img :src="actuImg(recent.img)" alt="">
        </div>
        <div class="about">
          <div class="date">{{ recent.createdAt }}</div>
          <div class="name">Admin</div>
          <!-- <div class="comment">
            <font-awesome-icon :icon="['fas', 'comment']" />
            6
          </div> -->
        </div>
        <div class="title">
          <router-link :to="{name: 'iactuality', params: {id: recent.id}}">
            {{ recent.title }}
          </router-link>
        </div>
        <div class="description">
          {{ recent.intro }}
        </div>
      </div>
      <!-- <div class="blog">
        <div class="image">
          <div class="img-back"></div>
          <img src="@/assets/cc.jpg" alt="">
        </div>
        <div class="about">
          <div class="date">AUG 17, 2023</div>
          <div class="name">Une actualité</div>
          <div class="comment">
            <font-awesome-icon :icon="['fas', 'comment']" />
            6
          </div>
        </div>
        <div class="title">
          <router-link :to="{name: 'iactuality', params: {id: 0}}">
            Une actualité
          </router-link>
        </div>
      </div>
      <div class="blog">
        <div class="image">
          <div class="img-back"></div>
          <img src="@/assets/cc.jpg" alt="">
        </div>
        <div class="about">
          <div class="date">AUG 17, 2023</div>
          <div class="name">François</div>
          <div class="comment">
            <font-awesome-icon :icon="['fas', 'comment']" />
            6
          </div>
        </div>
        <div class="title">
          <router-link :to="{name: 'iactuality', params: {id: 0}}">
            Une actualité
          </router-link>
        </div>
      </div> -->
    </div>
    <router-link :to="{name: 'iactualities'}">
      <div id="voir-plus">
        Voir plus 
        <font-awesome-icon :icon="['fas', 'arrow-right']" />
      </div>
    </router-link>
  </div>
</template>

<script>

import axios from 'axios';
import PartenariatSlider from '@/components/PartenariatSlider.vue'
import WelcomeSliderComponent from '@/components/WelcomeSliderComponent.vue';
// static actualities
import { actusRecents} from '@/data/actualites.js';

export default {
  name: 'IHomeView',
  data() {
    return {
      currentIndex: 0,
      count: {
        years: 0,
        collabs: 0,
        projects: 0
      }
    }
  },
  computed: {
    needChange() {
      return this.need_change;
    },
    current(id) {
      console.log(id);
      return true;
    },
    recents() {
      return actusRecents;
    }
  },
  components: {
    PartenariatSlider,
    WelcomeSliderComponent,
  },
  methods: {
    scrollToTop() {
      const el = this.$refs.top;
      if(el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    },
    fetchActualities() {
      axios.get(process.env.VUE_APP_API+"/publication/recents")
      .then((response) => {
        this.actualities = [...response.data];
      })
      .catch((error) => {
        console.error(error);
      })
    },
    actuImg(img) {
      return require('@/assets/actus/'+img);
    },
    reactiveCount() {
      const YEARS = 14, COLLABS = 20, PROJECTS = 20;
      const interval = setInterval(() => {
        if(this.count.years == YEARS && this.count.collabs == COLLABS && this.count.projects == PROJECTS) {
          clearInterval(interval);
          return ;
        }
        if(this.count.years < YEARS) {
          this.count.years++;
        }
        if(this.count.collabs < COLLABS) {
          this.count.collabs++;
        }
        if(this.count.projects < PROJECTS) {
          this.count.projects++;
        }
      }, 100);
    },
    actuLinkHandler(id) {
      this.$router.push({name: 'iactuality', params: {id: id}});
    }
  },
  mounted() {
    this.scrollToTop();
    const video = document.getElementById('background-video');
    video.playbackRate = 0.2;
    window.addEventListener('scroll', this.reactiveCount);
  },
}

</script>

<style lang="scss" scoped>
#welcome-block {
  position: relative;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  // background-image: url("@/assets/iob.jpg");
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
  video {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  
  #welcome {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    // opacity: .8;
    // background-color: #207dffcc;
    background-color: #00194cd7;
    padding: 6rem 0;
    box-sizing: border-box;

    @media (min-width: 750px) {
      padding: 8rem 0;
    }
    #title {
      color: #fff;
      font-size: 1.4rem;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 4px;
      @media (min-width: 750px) {
        font-size: 2rem;
      }
    }

    #content {
      font-weight: 100;
      font-size: 2rem;
      color: #fff;
      line-height: 3.5rem;
      margin-top: 1rem;
      transition: .4s ease all;
      &.animate-text {
        animation-name: textChangeAnimation;
        animation-duration: 1.5s;
      }
    }

    #contact-us-btn {
      color: #fff;
      border-radius: .2rem;
      padding: 0.7rem 1rem;
      margin-top: 1rem;
      border: 1px solid #fff;
      background-color: #ffffff4f;
      font-size: 1.1rem;
      text-decoration: none;

      &:hover {
        background-color: #00194c;
        border: 1px solid #00194c;
        box-shadow: 0 0 0.3rem #ffffffc0;
      }
    }
  }
}

#stat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  margin: 4rem auto;
  @media (min-width: 750px) {
    flex-direction: row;
  }
  @media (min-width: 990px) {
    width: 60%;
  }
  .item {
    margin: 2rem 0;
    .chiffre {
      color: #00194c;
      font-size: 2rem;
    }
    .title {
      text-transform: uppercase;
      margin-top: 1rem;
      color: rgba(0, 0, 0);
      font-size: 1.2rem;
    }
  }
}

#why-us {
  margin-top: 3rem;
  width: 100%;
  box-sizing: border-box;
  margin-top: 3rem;
  #cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 2rem;
    padding-top: 0;
    box-sizing: border-box;
    @media (min-width: 750px) {
    }
    @media (min-width: 990px) {
      padding: 0 5rem;
    }
    .card {
      padding: 2rem;
      margin: 1rem;
      width: 80%;
      transition: .6s ease all;
      box-sizing: border-box;
      @media (min-width: 750px) {
        width: calc(50% - 2rem);
      }
      @media (min-width: 990px) {
        margin: 2rem;
        width: calc(33% - 4rem);
      }
      .icon {
        font-size: 4rem;
        transition: .4s ease all;
        height: 4rem;
        color: #00194c;
        // color: #207dff;
      }
      .title {
        margin-top: 2rem;
        font-size: 1.3rem;
        // color: #000;
        color: #00194c;
        font-weight: 800;
        transition: .4s ease all;
      }
      .content {
        line-height: 1.8rem;
        height: 5.4rem;
        overflow-y: hidden;
        margin-top: 1rem;
        font-size: 1rem;
        // color: gray;
        color: rgb(46, 46, 46);
        font-weight: 400;
        transition: .4s ease all;
      }
      &:hover {
        border-radius: 0.3rem;
        // background-color: #207dff;
        background-color: #00194c;
        .icon {
          color: #ffffff00;
          height: 0rem;
        }
        .title {
          color: #fff;
        }
        .content {
          color: #ffffffcc;
          height: 9rem;
          overflow-y: scroll;
          scrollbar-color: #ffffff3f #ffffff00;
          /* Style the scrollbar background */
          ::-webkit-scrollbar {
            background: #ffffff3f;
          }

          /* Style the scrollbar handle */
          ::-webkit-scrollbar-thumb {
            background: #ffffff00;
          }
        }
      }
    }
  }
}

#nos-valeurs-block {
  width: 100%;
  box-sizing: border-box;
  margin-top: 3rem;
  @media (min-width: 750px) {
    margin-top: 5rem;
  }
  #nos-valeurs {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 2rem;
    box-sizing: border-box;
    @media (min-width: 950px) {
      padding: 4rem 8rem;
    }
    #image-valeurs {
      width: 100%;
      @media (min-width: 950px) {
        width: 50%;
      }
      img {
        width: 100%;
      }
    }
    #content-valeurs {
      width: 100%;
      @media (min-width: 950px) {
        width: 50%;
      }
      .val {
        margin-bottom: 1.7rem;
        text-align: start;
        line-height: 1.7rem;
        font-weight: 300;
        color: #000;
        text-align: justify;
        @media (min-width: 950px) {
          padding-left: 1rem;
        }
        .title {
          font-weight: normal;
          // color: #17a2b8;
          color: #00194c;
        }
      }
    }
  }
  #portefolio {
    display: flex;
    justify-content: end;
    a {
      text-decoration: none;
    }
    #pf-button {
      cursor: pointer;
      color: #fff;
      background-color: #00194c;
      // border: 1px solid #207dff;
      border: 1px solid #00194c;
      // border: 1px solid #fff;
      border-radius: .2rem;
      font-weight: 600;
      padding: 0.7rem 1rem;
      transition: .4s ease all;
      font-size: 1rem;
      margin-right: 2rem;
      @media (min-width: 950px) {
        margin-right: 8rem;
        font-size: 1.2rem;
      }
      &:hover {
        box-shadow: 0 0 0.4rem rgb(150, 150, 150);
      }
    }
  }
}

#infrastructure-block {
  width: 100%;
  margin-top: 3rem;
  box-sizing: border-box;
  @media (min-width: 750px) {
    margin-top: 5rem;
  }

  #infrastructure {
    display: flex;
    flex-wrap: wrap;
    // background-color: #207dff;
    padding: 1.5rem;
    width: 100%;
    min-height: 100vh;
    margin-top: 2rem;
    box-sizing: border-box;
    @media (min-width: 750px) {
      padding: 3rem;
    }

    .card {
      padding: 4rem 2rem;
      // background-color: #2e4158;
      // background-color: #ffffff08;
      background-color: rgb(219, 219, 219);
      margin: 2rem auto;
      width: 80%;
      height: auto;
      border-radius: 0.35rem;
      text-overflow: ellipsis;
      transition: .4s ease all;
      box-sizing: border-box;
      @media (min-width: 750px) {
        width: 45%;
      }
      @media (min-width: 950px) {
        width: 22%;
      }

      &:hover {
        // background-color: #fff;
        background-color: #00194c;
        .icon {
          background-color: #00194c;
          // background-color: #207dff;
        }
        .title {
          color: #ffffff;
          // color: #00194c;
          // color: #207dff;
        }
        .content {
          color: #ffffff;
          overflow-y: scroll;
          scrollbar-color: #ffffff85 #ffffff85;
          // color: #00194c;
          // color: rgb(39, 39, 39);
          /* Style the scrollbar background */
          ::-webkit-scrollbar {
            background: #ffffff85;
          }

          /* Style the scrollbar handle */
          ::-webkit-scrollbar-thumb {
            background: #ffffff85;
          }
        }
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 4rem;
        height: 4rem;
        font-size: 1.2rem;
        color: white;
        background-color: #00194c;
        // background-color: #85b8ff;
        border-radius: 50%;
      }

      .title {
        // color: rgb(240, 240, 240);
        color: #00194c;
        margin-top: 2rem;
        line-height: 1.5rem;
        font-size: 1.2rem;
        font-weight: 700;
        font-family: "Oswald", sans-serif;
      }

      .content {
        // color: #888888;
        // color: #fff;
        color: #00194c;
        margin-top: 1.7rem;
        line-height: 1.3rem;
        font-size: 1rem;
        font-family: "Roboto", sans-serif;
        // height: 4.9rem;
        height: calc(1.3rem * 3);
        overflow-y: hidden;
      }
    }
  }
}

#satisfied {
  width: 100%;
  margin-top: 3rem;
  @media (min-width: 750px) {
    margin-top: 5rem;
  }
}

#blog-recent-block {
  padding-bottom: 4rem;
  margin-top: 3rem;
  box-sizing: border-box;
  @media (min-width: 750px) {
    margin-top: 5rem;
  }
  #blog-recent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    @media (min-width: 750px) {
      padding: 4rem;
    }
    @media (min-width: 950px) {
      padding: 4rem 8rem;
    }
    .blog {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 2rem 0;
      background-color: #ffffff;
      padding-bottom: 1rem;
      box-sizing: border-box;
      @media (min-width: 750px) {
        width: 45%;
      }
      @media (min-width: 950px) {
        width: 30%;
      }
      &:hover {
        .image .img-back {
          background-color: #207dff17;
        }
      }
      .image {
        position: relative;
        z-index: 0;
        width: 100%;
        height: 18rem;
        .img-back {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #207dff4d;
          transition: .3s ease all;
        }
        img {
          position: relative;
          z-index: 0;
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
          border-radius: 0.2rem;
        }
      }
      .about {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
        color: gray;
        .date {
          font-size: 0.8rem;
          text-transform: uppercase;
        }
        .name {
          text-transform: uppercase;
          margin-left: 1rem;
        }
        .comment {
          margin-left: 1rem;
        }
      }
      .title {
        margin-bottom: 1rem;
        padding-top: 1rem;
        font-size: 1.2rem;
        padding: 0 1rem;
        box-sizing: border-box;
        a {
          text-decoration: none;
          // color: #2c3e50;
          color: #00194c;
        }
      }
      .description {
        margin-top: auto;
        text-align: justify;
        line-height: 1.5rem;
        height: 4.5rem;
        padding: 0 1rem;
        box-sizing: border-box;
        overflow-y: hidden;
      }
    }
  }
  #voir-plus {
    display: inline-block;
    color: #00194c;
    padding: 0.7rem 1.7rem;
    transition: .5s ease all;
    &:hover {
      background-color: #00194c;
      color: #fff;
      border-radius: 0.3rem;
    }
  }
}

.block-title {
  width: 80%;
  margin: 0 auto;
  font-size: 2rem;
  // color: #000;
  color: #00194c;
  font-weight: 400;
}

@keyframes textChangeAnimation {
  0% { opacity: 1; transform: translateY(0); }
  50% { opacity: 0; transform: translateY(2rem); }
  100% { opacity: 1; transform: translateY(0); }
}
</style>