<template>
  <div id="cards-block">
    <div class="block-title">
      Ils nous ont fait confiance 
    </div>
    <div id="cards">
      <div class="card-block">
        <div class="card">
          <div class="image-and-word">
            <div class="author-image">
              <div class="img-block">
                <div class="img-back"></div>
                <img src="@/assets/partenar/oscar.png" alt="">
                <div class="img-glm">
                  <font-awesome-icon :icon="['fas', 'quote-left']" />
                </div>
              </div>
            </div>
            <div class="word">
              Oscar is a health-tech startup providing patients with "owlistic" support throughout their cardiology journey 🦉❤️. NSI has reduced our costs while increasing the quality of our customer service. Their call center expertise
            </div>
          </div>
          <div class="author-name">
            Oscar
          </div>
        </div>
      </div>
      <div class="card-block">
        <div class="card">
          <div class="image-and-word">
            <div class="author-image">
              <div class="img-block">
                <div class="img-back"></div>
                <img src="@/assets/partenar/pierre.jpeg" alt="">
                <div class="img-glm">
                  <font-awesome-icon :icon="['fas', 'quote-left']" />
                </div>
              </div>
            </div>
            <div class="word">
              Lifeness a pour objectif de devenir le leader français du bien être physique et mental. et envisage conquérir aussi le marché européen dans le futur. NSI  a concrétisé mon projet
            </div>
          </div>
          <div class="author-name">
            Pierre de Lifeness
          </div>
        </div>
      </div>
      <div class="card-block">
        <div class="card">
          <div class="image-and-word">
            <div class="author-image">
              <div class="img-block">
                <div class="img-back"></div>
                <img src="@/assets/partenar/charpentier.jpeg" alt="">
                <div class="img-glm">
                  <font-awesome-icon :icon="['fas', 'quote-left']" />
                </div>
              </div>
            </div>
            <div class="word">
              Tunix c'est le nouveau réseau social sportif professionnel qui permet de rencontrer ses collaborateurs et réserver des sessions de sports à prix réduits. Je suis extrêmement reconnaissant envers l'équipe IT de NSI pour avoir matérialisé mon rêve.
            </div>
          </div>
          <div class="author-name">
            Charpentier de Tunix
          </div>
        </div>
      </div>
      <div class="card-block">
        <div class="card">
          <div class="image-and-word">
            <div class="author-image">
              <div class="img-block">
                <div class="img-back"></div>
                <img src="@/assets/partenar/allomedic.png" alt="">
                <div class="img-glm">
                  <font-awesome-icon :icon="['fas', 'quote-left']" />
                </div>
              </div>
            </div>
            <div class="word">
              Le service client est au cœur de notre entreprise médicale, et NSI l'a compris. Leur équipe de Télésecrétaires a surpassé nos attentes en offrant un service exceptionnel à nos clients, renforçant ainsi notre réputation
            </div>
          </div>
          <div class="author-name">
            Allo Médic
          </div>
        </div>
      </div>
      <div class="card-block">
        <div class="card">
          <div class="image-and-word">
            <div class="author-image">
              <div class="img-block">
                <div class="img-back"></div>
                <img src="@/assets/partenar/jules.png" alt="">
                <div class="img-glm">
                  <font-awesome-icon :icon="['fas', 'quote-left']" />
                </div>
              </div>
            </div>
            <div class="word">
              ASK ME A FAVOR! est une société qui a développé une application du même nom qui permet à un particulier d'effectuer des livraisons sur son trajet pour avoir un complément de revenu. Pour cela nous avons créé un portail internet et une application mobile disponible sur Android et IOS. Grâce à NSI, nous avons réussi à transformer une vision en succès concret
            </div>
          </div>
          <div class="author-name">
            Jules de Ask Me a Favor
          </div>
        </div>
      </div>
      <div class="card-block">
        <div class="card">
          <div class="image-and-word">
            <div class="author-image">
              <div class="img-block">
                <div class="img-back"></div>
                <img src="@/assets/partenar/mind.png" alt="">
                <div class="img-glm">
                  <font-awesome-icon :icon="['fas', 'quote-left']" />
                </div>
              </div>
            </div>
            <div class="word">
              Projet de stockage de données en ligne en mode SAAS et pouvant convenir aussi bien à des TPE et que des ETI. Le moteur d’indexation sera basé sur de l’IA et machine learning pour s’adapter à tous les process métiers des entreprises. NSI a été le partenaire clé qui a fait de notre projet une réalité
            </div>
          </div>
          <div class="author-name">
            Mind Flow
          </div>
        </div>
      </div>
      <div class="card-block">
        <div class="card">
          <div class="image-and-word">
            <div class="author-image">
              <div class="img-block">
                <div class="img-back"></div>
                <img src="@/assets/partenar/dial.png" alt="">
                <div class="img-glm">
                  <font-awesome-icon :icon="['fas', 'quote-left']" />
                </div>
              </div>
            </div>
            <div class="word">
              NSI a simplifié notre gestion des appels entrants de nos clients manière remarquable. Leurs solutions personnalisées ont considérablement amélioré notre efficacité, nous permettant de nous concentrer sur notre croissance
            </div>
          </div>
          <div class="author-name">
            Dial Auto
          </div>
        </div>
      </div>
      <div class="card-block">
        <div class="card">
          <div class="image-and-word">
            <div class="author-image">
              <div class="img-block">
                <div class="img-back"></div>
                <img src="@/assets/partenar/farid.png" alt="">
                <div class="img-glm">
                  <font-awesome-icon :icon="['fas', 'quote-left']" />
                </div>
              </div>
            </div>
            <div class="word">
              Start-up dans les métiers de la tech & d'ingénierie : Créée en 2022 à Paris, développe une application digitale dédiée à la gestion des chantiers et propose des prestations d’ingénierie en génie électrique CVC et BIM (bureau d'étude).  Nous sommes fiers de notre partenariat continu avec NSI.
            </div>
          </div>
          <div class="author-name">
            Farid
          </div>
        </div>
      </div>
      <div class="card-block">
        <div class="card">
          <div class="image-and-word">
            <div class="author-image">
              <div class="img-block">
                <div class="img-back"></div>
                <img src="@/assets/partenar/offcorse.png" alt="">
                <div class="img-glm">
                  <font-awesome-icon :icon="['fas', 'quote-left']" />
                </div>
              </div>
            </div>
            <div class="word">
              Plateforme dédiée à l'enseignement de matières scientifiques (dans un premier temps) : Maths, Physique-Chimie, SVT, Informatique, ...Cours en ligne gratuit, offre de formation en ligne / présentiel, test / quizz sur la matière. NSI a été la force motrice derrière la réalisation de notre projet
            </div>
          </div>
          <div class="author-name">
            Offcours
          </div>
        </div>
      </div>
    </div>
    <div id="controlls">
      <div id="prev" @click="() => {gotoHandler(false)}">
        <font-awesome-icon :icon="['fas', 'chevron-left']" />
      </div>
      <div id="next" @click="() => {gotoHandler()}">
        <font-awesome-icon :icon="['fas', 'chevron-right']" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PartnariatSlider',
  data() {
    return {
      containerWidth: 0,
      itemsWidth: 0,
      itemsCount: 9,
      itemsVisible: 3,
      defaultItemsVisible: 3,
      currentIndex: 0,
      translateX: 0,
      intervalRef: null
    }
  },
  computed: {
    _containerWidth: function () {
      return this.containerWidth+"%";
    }
  },
  methods: {
    defineElementsSize() {
      let ratio = this.itemsCount / this.itemsVisible;
      this.containerWidth = (ratio * 100)+"%"; // en pourcentage
      this.itemsWidth = (100 / this.itemsVisible / ratio)+"%"; // en pourcentage
    },
    defineSize() {
      if(window.innerWidth <= 750) {
        this.itemsVisible = 1;
        return;
      }
      if(window.innerWidth <= 950) {
        this.itemsVisible = 2;
        return;
      }
      this.itemsVisible = this.defaultItemsVisible;
    },
    goto(index) {
      if(index < 0) {
        index = this.itemsCount - this.itemsVisible;
      }
      else {
        if(index > this.itemsCount - this.itemsVisible) {
          index = 0;
        }
      } 
      this.translateX = (index * -100 / this.itemsCount)+"%";
      this.currentIndex = index;
    },
    gotoHandler(isNext = true) {
      if(isNext) {
        this.goto(this.currentIndex + 1); 
        clearInterval(this.intervalRef);
        this.intervalRef = setInterval(() => {
          this.goto(this.currentIndex + 1);
        }, 12000);
      }else {
        this.goto(this.currentIndex - 1);
        clearInterval(this.intervalRef);
        this.intervalRef = setInterval(() => {
          this.goto(this.currentIndex + 1);
        }, 12000);
      }
    }
  },
  created() {
    window.addEventListener('resize', () => {
      let actualItemsVisible = this.itemsVisible;
      this.defineSize();
      // si on a fait une changement sur sliderVisible
      if(actualItemsVisible !== this.itemsVisible){
          this.defineElementsSize();
          this.goto(0);
      }
    });
    this.intervalRef = setInterval(() => {
      this.goto(this.currentIndex + 1);
    }, 12000);
  },
  mounted() {
    this.defineSize();
    this.defineElementsSize();
  }
}

</script>

<style lang="scss" scoped>
#cards-block {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  @media (min-width: 750px) {
    width: 80vw;
  }
  #cards {
    display: flex;
    position: relative;
    width: v-bind('containerWidth');
    transition: .7s ease all;
    transform: translateX(v-bind('translateX'));
    .card-block {
      padding: 4rem;
      box-sizing: border-box;
      width: v-bind('itemsWidth');
      .card {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        .image-and-word {
          .author-image {
            .img-block {
              position: relative;
              width: 6rem;
              height: 6rem;
              margin: 0 auto;
              .img-back {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 6rem;
                height: 6rem;
                background-color: #00194c49;
                // background-color: #17a3b849;
                border-radius: 50%;
              }
              img {
                position: relative;
                z-index: 0;
                width: 6rem;
                height: 6rem;
                object-fit: cover;
                border-radius: 50%;
              }
              .img-glm{
                position: absolute;
                z-index: 2;
                bottom: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.5rem;
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
                // background-color: #17a3b8;
                background-color: #00194c;
                color: white;
              }
            }
          }
          .word {
            margin-top: 1rem;
            color: gray;
            line-height: 1.8rem;
            height: 7.2rem;
            overflow: hidden;
            padding: 0 1rem;
            &:hover {
              overflow-y: scroll;
              overflow-y: overlay;
              scrollbar-color: #66666686 #ffffff00;
              
              ::-webkit-scrollbar {
                background: #66666686;
              }

              /* Style the scrollbar handle */
              ::-webkit-scrollbar-thumb {
                background: #ffffff00;
              }
            }
          }
        }
        .author-name {
          margin-top: 2rem;
          // color: #000;
          color: #00194c;
        }
      }
    }
  } 
  #controlls {
    & > div {
      position: absolute;
      font-size: 3rem;
      &#prev {
        top: calc(50% + 1.5rem);
        left: 1.5rem;
      }
      &#next {
        top: calc(50% + 1.5rem);
        right: 1.5rem;
      }
    }
  }
}

.block-title {
  font-size: 2rem;
  text-align: center;
  // color: #000;
  color: #00194c;
  font-weight: 400;
}
</style>