<template>
  <div ref="top" id="top"></div>
  <div id="travailler-avec-nous-block">
    <div id="travailler-avec-nous">
      <div class="block-title">Travailler avec nous</div>
      <!-- phrase d'introduction -->
      <div id="init-phrase">
        Envisagez-vous de déléguer une partie de vos opérations pour vous concentrer davantage sur votre domaine principal d'activité ? Utilisez ce formulaire concis pour nous fournir davantage d'informations. Passons à l'étape suivante ! Soyez assuré(e) que toutes les données collectées via ce questionnaire seront traitées avec la plus stricte confidentialité. Promis ! 
      </div>
      <!-- formulaire -->
      <div id="form">
        <form action="" @submit.prevent="submitMessageHandler">
          <div id="message" v-show="hasMessage" :class="message.status" ref="message">
            {{ message.message }}
          </div>
          <!-- name field -->
          <div class="field" :class="{'has-error': errors.name}">
            <input type="text" placeholder="Votre nom" v-model="form.name">
            <div class="error" v-show="errors.name">{{ errors.name }}</div>
          </div>
          <!-- email field field -->
          <div class="field" :class="{'has-error': errors.email}">
            <input type="email" placeholder="Votre email" v-model="form.email"> 
            <div class="error" v-show="errors.email">{{ errors.email }}</div>
          </div>
          <!-- subject field -->
          <div class="field" :class="{'has-error': errors.subject}">
            <input type="tel" placeholder="Objet" v-model="form.subject">
            <div class="error" v-show="errors.subject">{{ errors.subject }}</div>
          </div>
          <!-- message field -->
          <div class="field" :class="{'has-error': errors.content}">
            <textarea placeholder="Veuillez décriver votre projet ici." v-model="form.content"></textarea>
            <div class="error" v-show="errors.content">{{ errors.content }}</div>
          </div>
          <button type="submit">
            <font-awesome-icon :icon="['fas', 'circle-notch']" id="loader" :class="{animate: load}" />
            Travailler avec nous
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default { 
  name: 'ITravaillerAvecNousView',
  data() {
    return {
      form: {
        name: '',
        email: '',
        subject: '',
        content: '',
      },
      errors: {},
      load: false,
      message: {
        message: "",
        status: ""
      },
    }
  },
  methods: {
    scrollToTop() {
      const el = this.$refs.top;
      if(el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    },
    scrollToMessage() {
      const el = this.$refs.message;
      if(el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    },
    submitMessageHandler() {
      this.errors = {};
      let hasError = false;
      // validation des données
      if(this.form.name == '') {
        this.errors.name = "Nom obligatoire";
        hasError = true;
      }
      if(this.form.email == '') {
        this.errors.email = "Email obligatoire";
        hasError = true;
      }
      if(this.form.subject == '') {
        this.errors.subject = "Objet obligatoire";
        hasError = true;
      }
      if(this.form.content == '') {
        this.errors.content = "Message obligatoire";
        hasError = true;
      }
      if(hasError) return ;
      
      this.errors = {};

      this.load = true;
      axios.post(process.env.VUE_APP_API+"/mail", this.form)
      .then((response) => {
        console.log("Success", response);
        this.message = {message: "Message envoyé avec succès", status: "success"};
        this.load = false;
        this.form = {
          name: '',
          email: '',
          // phone: '',
          subject: '',
          content: '',
        };
        this.scrollToMessage();
      })
      .catch((errors) => {
        console.error("problem", errors);
        this.message = {message: "Message non envoyé à cause d'un probleme technique", status: "failure"}
        this.load = false;
        this.scrollToMessage();
      });
    },
  },  
  computed: {
    hasMessage() {
      return this.message.message != "";
    }
  },
  mounted() {
    this.scrollToTop();
  }
}

</script>

<style lang="scss" scoped>
#top {
  margin-top: 50px;
}
#travailler-avec-nous-block {
  // background-color: rgb(247, 247, 247);
  width: 100%;
  box-sizing: border-box;
  padding: 6rem 0;
  #travailler-avec-nous {
    #init-phrase {
      font-weight: 400;
      line-height: 1.8rem;
      color: rgb(56, 56, 56);
      // color: #eeeeee;
      margin-top: 1rem;
      width: 90%;
      margin: 0 auto;
      margin-top: 1rem;
      font-weight: 400;
      text-align: justify;
      @media (min-width: 750px) {
        text-align: center;
        width: 70%;
      }
    }
  }
}

#form {
  background-color: rgb(255, 255, 255);
  width: 90%;
  margin: 0 auto;
  border-radius: 0.2rem;
  margin-top: 2rem;
  padding: 2rem;
  box-sizing: border-box;
  @media (min-width: 750px) {
    width: 70%;
    padding: 3rem;
  }
  .field {
    margin-bottom: 1rem;
    input, textarea {
      border: 1px solid #dfdfdf;
      padding: 0.7rem 1rem;
      border-radius: 0.3rem;
      outline: none;
      width: 100%;
      font-size: 1rem;
      box-sizing: border-box;
      box-shadow: 0 0 0.4rem #a8a8a849;
      &:focus {
        box-shadow: 0 0 0.2rem #a8a8a8fe;
        border: 1px solid #3d3d3dce;
      }
      &::placeholder {
        font-size: 1rem;
      }
    }
    textarea {
      width: 100%;
      height: 12rem;
      box-sizing: border-box;
    }
    &.has-error {
      input, textarea {
        border: 1px solid #ff4242;
        box-shadow: 0 0 0.4rem #ff4242;
      }
      .error {
        text-align: start;
        font-size: 0.8rem;
        color: #d85a5a;
      }
    }
  }
  button[type="submit"] {
    display: block;
    padding: 1rem 1.5rem;
    border: 1px solid #00194c;
    border-radius: 0.2rem;
    background-color: #00194c;
    color: #fff;
    font-size: 1.1rem;
    transition: .3s ease all;
    &:hover {
      background-color: #fff;
      color: #00194c;
    }
  }
  #loader.animate {
    animation: loaderAnimation;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  #message {
    width: 100%;
    color: #ffffff;
    padding: 1rem 0;
    margin-bottom: 2rem;
    box-sizing: border-box;
    border-radius: 0.3rem;
    &.success {
      background-color: #2ad346;
    }
    &.failure {
      background-color: #eb7a1d;
    }
  }
}

.block-title {
  width: 80%;
  margin: 0 auto;
  color: #00194c;
  // color: rgb(0, 0, 180);
  font-weight: 400;
  font-size: 1.4rem;
  @media (min-width: 750px) {
    font-size: 2rem;
  }
}

@keyframes loaderAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>