import { createRouter, createWebHistory } from 'vue-router'

import IHomeView from '../views/IHomeView.vue'
import IContact from '../views/IContactView.vue'
import IActualitiesView from '../views/IActualitiesView.vue' 
import IActualityView from '../views/IActualityView.vue'
import INosExpertisesView from '../views/INosExpertisesView.vue';
import ITravaillerAvecNousView from '../views/ITravaillerAvecNousView.vue';

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }, 

  // IGNITE
  {
    path: '',
    name: 'iaccueil',
    component: IHomeView
  },
  {
    path: '/contact',
    name: 'icontact',
    component: IContact
  },
  {
    path: '/actualite',
    name: 'iactualities',
    component: IActualitiesView
  },
  {
    path: '/actualite/details/:id',
    name: 'iactuality',
    component: IActualityView,
    props: true
  },
  {
    path: '/nos-expertises',
    name: 'inosexpertises',
    component: INosExpertisesView
  },
  {
    path: '/travailler-avec-nous',
    name: 'itravailleravecnous',
    component: ITravaillerAvecNousView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
