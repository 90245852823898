<template>
  <div ref="top" id="top"></div>
  <div id="contact">
    <!-- contact data -->
    <div id="contacts">
      <!-- <div class="contact">
        <div class="icon">
          <font-awesome-icon :icon="['far', 'map']" />
        </div>
        <div class="title">Adresse</div>
        <div class="content">F II 54 bis Ambohimahintsy Tana 103</div>
      </div> -->
      <div class="contact">
        <div class="icon">
          <font-awesome-icon :icon="['fas', 'mobile-screen']" />
        </div>
        <div class="title">Phone</div>
        <div class="content">+261 32 77 013 64</div>
        <div class="content">+261 32 78 455 62</div>
      </div>
      <div class="contact">
        <div class="icon">
          <font-awesome-icon :icon="['far', 'envelope']" />
        </div>
        <div class="title">Email</div>
        <div class="content">
          <a href="mailto:contact@nsi-mada.com">contact@nsi-mada.com</a>
        </div>
      </div>
      <div class="contact">
        <div class="icon">
          <font-awesome-icon :icon="['fab', 'facebook-f']" />
        </div>
        <div class="title">Facebook</div>
        <div class="content">
          <a href="https://www.facebook.com/nsimada.call" target="_blank">
            NSi.mada
          </a>
        </div>
      </div>
      <div class="contact">
        <div class="icon">
          <font-awesome-icon :icon="['fab', 'linkedin']" />
        </div>
        <div class="title">LinkedIn</div>
        <div class="content">
          <a href="https://www.linkedin.com/company/nsi-mada" target="_blank">NSI Mada</a>
        </div>
      </div>
    </div>
    <!-- travailler avec nous -->
    <div id="travailler-avec-nous">
      Nous vous invitons de nous contacter si vous avez des questions
    </div>
    <!-- formulaire -->
    <div id="form">
      <form action="" @submit.prevent="submitMessageHandler">
        <div id="message" v-show="hasMessage" :class="message.status" ref="message">
          {{ message.message }}
        </div>
        <!-- name field -->
        <div class="field" :class="{'has-error': errors.name}">
          <input type="text" placeholder="Votre nom" v-model="form.name">
          <div class="error" v-show="errors.name">{{ errors.name }}</div>
        </div>
        <!-- email field field -->
        <div class="field" :class="{'has-error': errors.email}">
          <input type="email" placeholder="Votre email" v-model="form.email"> 
          <div class="error" v-show="errors.email">{{ errors.email }}</div>
        </div>
        <!-- subject field -->
        <div class="field" :class="{'has-error': errors.subject}">
          <input type="text" placeholder="Objet" v-model="form.subject"> 
          <div class="error" v-show="errors.subject">{{ errors.subject }}</div>
        </div>
        <!-- message field -->
        <div class="field" :class="{'has-error': errors.content}">
          <textarea placeholder="Votre message" v-model="form.content"></textarea>
          <div class="error" v-show="errors.content">{{ errors.content }}</div>
        </div>
        <button type="submit">
          <font-awesome-icon :icon="['fas', 'circle-notch']" id="loader" :class="{animate: load}" />
          Envoyer message
        </button>
      </form>
    </div>
    <!-- google map -->
    <!-- <map-component /> -->
  </div>
</template>

<script>

import axios from 'axios';
// import MapComponent from '@/components/MapComponent.vue'

export default {
  name: 'IContactView',
  data() {
    return {
      form: {
        name: '',
        email: '',
        // phone: '',
        subject: '',
        content: '',
      },
      errors: {},
      load: false,
      message: {
        message: "",
        status: ""
      }
    }
  },
  methods: {
    scrollToTop() {
      const el = this.$refs.top;
      if(el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    },
    scrollToMessage() {
      const el = this.$refs.message;
      if(el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    },
    submitMessageHandler() {
      // this.message = {mes};
      this.errors = {};
      let hasError = false;
      // validation des données
      if(this.form.name == '') {
        this.errors.name = "Nom obligatoire";
        hasError = true;
      }
      if(this.form.email == '') {
        this.errors.email = "Email obligatoire";
        hasError = true;
      }
      if(this.form.subject == '') {
        this.errors.subject = "Objet obligatoire";
        hasError = true;
      }
      if(this.form.content == '') {
        this.errors.content = "Message obligatoire";
        hasError = true;
      }
      if(hasError) return ;
      
      this.errors = {};

      this.load = true;
      axios.post(process.env.VUE_APP_API+"/mail", this.form)
      .then((response) => {
        console.log("Success", response);
        this.message = {message: "Message envoyé avec succès", status: "success"};
        this.load = false;
        this.form = {
          name: '',
          email: '',
          // phone: '',
          subject: '',
          content: '',
        };
        this.scrollToMessage();
      })
      .catch((errors) => {
        console.error("problem", errors);
        this.message = {message: "Message non envoyé à cause d'un probleme technique", status: "failure"}
        this.load = false;
        this.scrollToMessage();
      });
    },
  },
  computed: {
    hasMessage() {
      return this.message.message != "";
    }
  },
  components: {
    // MapComponent
  },
  mounted() {
    this.scrollToTop();
  }
}

</script>

<style lang="scss" scoped>
#top {
  margin-top: 50px;
}
#contact {
  // background-color: #207dff;
  padding-bottom: 4rem;
  padding-top: 3rem;
  // padding: 5rem 0;
  @media (min-width: 750px) {
    padding-top: 6rem;
  }
}

#contacts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 3rem 0;
  box-sizing: border-box;
  @media (min-width: 750px) {
    width: 65%;
    margin: 0 auto;
  }
  @media (min-width: 950px) {
    width: 80%;
    margin: 0 auto;
  }

  .contact {
    color: #00194c;
    width: 80%;
    margin: 1rem auto;
    padding: 0 1rem;
    box-sizing: border-box;
    @media (min-width: 750px) {
      width: 30%;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      font-size: 2rem;
    }
    .title {
      margin-top: 1rem;
      font-size: 1.3rem;
    }
    .content {
      font-size: 1rem;
      margin-top: 1rem;
      font-weight: 400;
      color: #00194c;
      a {
        text-decoration: none;
        color: #00194c;
      }
    }
  }
  .separator {
    height: 0;
    width: 80%;
    margin: auto;
    border-right: none;
    // border-bottom: 1px solid #ffffff33;
    border-bottom: 1px solid #00194c;
    @media (min-width: 750px) {
      height: 10rem;
    // border-bottom: 1px solid #ffffff33;
      border-right: 1px solid #00194c;
      border-bottom: none;
      width: 0;
    }
  }
}

#travailler-avec-nous {
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0;
  color: #303030;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
  @media (min-width: 750px) {
    width: 70%;
  }
}

#form {
  background-color: rgb(255, 255, 255);
  width: 90%;
  margin: 0 auto;
  border-radius: 0.2rem;
  padding: 2rem;
  box-sizing: border-box;
  @media (min-width: 750px) {
    width: 70%;
    padding: 3rem;
  }
  .field {
    margin-bottom: 1rem;
    input, textarea {
      border: 1px solid #dfdfdf;
      padding: 0.7rem 1rem;
      border-radius: 0.3rem;
      outline: none;
      width: 100%;
      font-size: 1rem;
      box-sizing: border-box;
      box-shadow: 0 0 0.4rem #a8a8a849;
      &:focus {
        box-shadow: 0 0 0.2rem #a8a8a8fe;
        border: 1px solid #3d3d3dce;
      }
      &::placeholder {
        font-size: 1rem;
      }
    }
    textarea {
      width: 100%;
      height: 12rem;
      box-sizing: border-box;
    }
    &.has-error {
      input, textarea {
        border: 1px solid #ff4242;
        box-shadow: 0 0 0.4rem #ff4242;
      }
      .error {
        text-align: start;
        font-size: 0.8rem;
        color: #d85a5a;
      }
    }
  }
  button[type="submit"] {
    display: block;
    padding: 1rem 1.5rem;
    border: 1px solid #00194c;
    border-radius: 0.2rem;
    background-color: #00194c;
    color: #fff;
    font-size: 1.1rem;
    transition: .3s ease all;
    &:hover {
      background-color: #ffffff;
      color: #00194c;
    }
  }
  #loader.animate {
    animation: loaderAnimation;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  #message {
    width: 100%;
    color: #ffffff;
    padding: 1rem 0;
    margin-bottom: 2rem;
    box-sizing: border-box;
    border-radius: 0.3rem;
    &.success {
      background-color: #2ad346;
    }
    &.failure {
      background-color: #eb7a1d;
    }
  }
}
@keyframes loaderAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>