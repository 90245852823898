<template>
  <nav :class="{'large': !mobile}">
    <div id="bars" :class="{open: isOpen }" @click="dropDownHandler" v-show="mobile">
      <div class="bar top"></div>
      <div class="bar middle"></div>
      <div class="bar bottom"></div>
    </div>
    <!-- for mobile -->
    <ul id="mobile-nav" :class="{open: isOpen}" class="nav-content" v-show="mobile">
      <li class="nsi-logo">
        <router-link :to="{name: 'iaccueil'}" @click="changeRoute">
          <img src="@/assets/logo.png" alt="" class="logo_sm">
        </router-link>
      </li>
      <li><router-link :to="{name: 'iaccueil'}" @click="changeRoute">Accueil</router-link></li>
      <li><router-link :to="{name: 'icontact'}" @click="changeRoute">Contacts</router-link></li>
      <li><router-link :to="{name: 'inosexpertises'}" @click="changeRoute">Nos expertises</router-link></li>
      <li><router-link :to="{name: 'iactualities'}" @click="changeRoute">Actualités</router-link></li>
      <li><router-link :to="{name: 'itravailleravecnous'}" @click="changeRoute">Travailler avec nous</router-link></li>
    </ul>
    <!-- for not mobile -->
    <ul id="sample-nav" :class="{open: isOpen, scrolled: isScrolled}" v-show="!mobile" class="nav-content">
      <li class="nsi-logo">
        <router-link :to="{name: 'iaccueil'}">
          <img src="@/assets/logo_sm.png" alt="" class="logo_sm">
        </router-link>
      </li>
      <li><router-link :to="{name: 'iaccueil'}">Accueil<div class="border-bot"></div></router-link></li>
      <li><router-link :to="{name: 'inosexpertises'}">Nos Expertises<div class="border-bot"></div></router-link></li>
      <li><router-link :to="{name: 'iactualities'}">Actualités<div class="border-bot"></div></router-link></li>
      <li><router-link :to="{name: 'icontact'}">Contacts<div class="border-bot"></div></router-link></li>
      <li><router-link :to="{name: 'itravailleravecnous'}">Travailler avec nous<div class="border-bot"></div></router-link></li>
    </ul>
  </nav>
</template>

<script>

export default {
  name: 'INavBar',
  data() {
    return {
      windowWidth: null,
      mobile: null,
      isOpen: null,
      isScrolled: null
    }
  },
  methods: {
    dropDownHandler() {
      this.isOpen = !this.isOpen;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      this.isOpen = false;
      if(this.windowWidth < 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      return;
    },
    checkScroll() {
      if(['inosexpertises', 'iactualities', 'iactuality', 'icontact', 'itravailleravecnous'].includes(this.$route.name)) {
      // if('iaccueil' != this.$route.name) {
        this.isScrolled = true;
        return ;
      }
      if(window.scrollY > 10) {
        this.isScrolled = true;
        return;
      }
      this.isScrolled = false;
      return;
    },
    changeRoute() {
      if(this.mobile && this.isOpen) {
        this.isOpen = false;
      }
    }
  },
  created() {
    window.addEventListener('resize', this.checkScreen);
    window.addEventListener('scroll', this.checkScroll);
  },
  mounted() {
    this.checkScreen();
    this.checkScroll();
  },
}

</script>

<style lang="scss" scoped>
ul {
  margin: 0;
}
li {
  list-style: none;
  padding: 0;
  a {
    color: black;
  }
}
a {
  text-decoration: none;
}
nav {
  position: relative;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  #sample-nav {
    display: flex;
    justify-content: end;
    align-items: center;
    text-transform: uppercase;
    height: 3rem;
    position: absolute;
    top: 1rem;
    width: 100%;
    padding: 2rem 2rem;
    box-sizing: border-box;
    transition: .5s ease all;

    .nsi-logo {
      margin-right: auto;
      img {
        // width: 10rem;
        width: 2rem;
        height: 2rem;
      }
    }

    li {
      margin-right: 2rem;
      a {
        font-size: .85rem;
        font-weight: 600;
        font-family: "Work Sans", Arial, sans-serif;
        color: #fff;
        // customized border bottom
        .border-bot {
          border-top: 2px solid #eee;
          width: 0px;
          transition: .3s ease width;
        }
        &:hover .border-bot {
          width: 100%;
        }
        &.router-link-active {
          // color: #a1dd70;
          color: #00194c;
          .border-bot {
            border-top: 2px solid #00194c;
          }
        }
      }
    }
    .devis-btn {
      cursor: pointer;
      color: #fff;
      border: 1px solid #fff;
      border-radius: .2rem;
      font-size: .85rem;
      font-weight: 600;
      padding: 0.7rem 1rem;
      transition: .4s ease all;
      &:hover {
        background-color: #207dff;
        border: 1px solid #207dff;
      }
    }
    &.scrolled {
      position: fixed;
      top: 0;
      left: 0;
      background-color: #fff;
      box-shadow: 0.5rem 0 0.5rem rgb(206, 206, 206);
      li a {
        color: #000;
        .border-bot {
          border-top: 2px solid #000;
        }
        &.router-link-active {
          color: #00194c;
          .border-bot {
            border-top: 2px solid #00194c;
          }
        }
      }
      .devis-btn {
        background-color: #207dff;
        border: 1px solid #207dff;
      }
    }
  }
  #bars {
    // background-color: red;
    position: fixed;
    top: 1rem;
    left: 1rem;
    padding: 0.7rem;
    z-index: 2;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 3px 3px 3px grey;
    .bar {
      width: 25px;
      height: 3px;
      background-color: rgb(37, 37, 37);
      border-radius: 1px;
      margin-top: 3.5px;
      margin-bottom: 3.5px;
      transform-origin: left;
      transition: .2s ease all;
    }

    &.open {
      box-shadow: none;
      .top {
        margin-bottom: 9px;
        transform: rotate(45deg);
      }
      .middle {
        width: 0px;
      }
      .bottom {
        transform: rotate(-45deg);
      }
    }
  }
  #mobile-nav {
    position: fixed;
    z-index: 1;
    top: -100%;
    left: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: #fff;
    width: 100%;
    transition: .4s ease-out all;
    padding: 1rem;
    padding-top: 3rem;
    box-sizing: border-box;

    .nsi-logo {
      margin-right: auto;
      img {
        width: 12rem;
      }
    }
    li {
      padding: 1rem 2rem;
      list-style: none;
      font-weight: bold;
      font-size: 1.1rem;
      width: 100%;
      border-bottom: 1px solid rgb(187, 187, 187, 0.3);
      box-shadow: 0px 0px 0px rgb(116, 116, 116);
      text-align: start;
      box-sizing: border-box;

      a {
        text-decoration: none;
        color: rgb(83, 83, 83);
        font-weight: 100;
        &.router-link-active {
          color: #207dff;
        }
      }
    }

    &.open {
      top: 0px;
    }
    .devis-btn {
      width: 100%;
      box-sizing: border-box;
      text-align: center;
    }
  }
}
</style>