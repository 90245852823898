<template>
  <div id="welcome-slider">
    <div id="words">
      <div class="word-block">
        <div class="word">
          NSI Mada vous accompagne dans vos démarches d’externalisation dans la réalisation de vos projets à court, à moyen et à long terme.
        </div>
      </div>
      <div class="word-block">
        <div class="word">
          Composée de jeunes passionnés, nos collaborateurs interviennent dans plusieurs domaines, multitâches et disposant des années d’expériences.
        </div>
      </div>
      <div class="word-block">
        <div class="word">
          Les managers vous accompagnent avec des équipes proactives, répondant aux attentes des clients dans la réalisation de leurs objectifs.
        </div>
      </div>
    </div>
    <div id="controlls">
      <div id="prev" @click="() => {gotoHandler(false)}">
        <font-awesome-icon :icon="['fas', 'chevron-left']" />
      </div>
      <div id="next" @click="() => {gotoHandler() }">
        <font-awesome-icon :icon="['fas', 'chevron-right']" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomeSliderComponent',
  data() {
    return {
      containerWidth: 0,
      itemsWidth: 0,
      itemsCount: 3,
      itemsVisible: 1,
      currentIndex: 0,
      translateX: 0,
      intervalRef: null
    }
  },
  computed: {
    _containerWidth: function () {
      return this.containerWidth+"%";
    }
  },
  methods: {
    defineElementsSize() {
      let ratio = this.itemsCount / this.itemsVisible;
      this.containerWidth = (ratio * 100)+"%"; // en pourcentage
      this.itemsWidth = (100 / this.itemsVisible / ratio)+"%"; // en pourcentage
    },
    goto(index) {
      if(index < 0) {
        index = this.itemsCount - this.itemsVisible;
      }
      else {
        if(index > this.itemsCount - this.itemsVisible) {
          index = 0;
        }
      } 
      this.translateX = (index * -100 / this.itemsCount)+"%";
      this.currentIndex = index;
    },
    gotoHandler(isNext = true) {
      if(isNext) {
        this.goto(this.currentIndex + 1); 
        clearInterval(this.intervalRef);
        this.intervalRef = setInterval(() => {
          this.goto(this.currentIndex + 1);
        }, 12000);
      }else {
        this.goto(this.currentIndex - 1);
        clearInterval(this.intervalRef);
        this.intervalRef = setInterval(() => {
          this.goto(this.currentIndex + 1);
        }, 12000);
      }
    }
  },
  created() {
    this.defineElementsSize();
    this.intervalRef = setInterval(() => {
      this.goto(this.currentIndex + 1);
    }, 12000);
  }
}
</script>

<style lang="scss" scoped>
  #welcome-slider {
    position: relative;
    width: 80vw;
    margin: 0 auto;
    overflow: hidden;
    #words {
      display: flex;
      align-items: center;
      position: relative;
      width: v-bind('containerWidth');
      transition: .8s ease all;
      transform: translateX(v-bind('translateX'));
      .word-block {
        padding: 1rem 3rem;
        box-sizing: border-box;
        width: v-bind('itemsWidth');
        .word {
          // color: #fff;
          color: #e2e2e2;
          font-size: 1.2rem;
          line-height: 1.8rem;
        }
      }
    }
    #controlls {
      color: #ffffff48;
      & > div {
        position: absolute;
        font-size: 3rem;
        &#prev {
          top: calc(50% - 1.5rem);
          left: 0;
        }
        &#next {
          top: calc(50% - 1.5rem);
          right: 0;
        }
        &:hover {
          color: #ffffff88;
        }
      }
    }
  }
</style>