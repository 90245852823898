<template>
  <div id="top" ref="top"></div>
  <!-- welcome block -->
  <!-- <div id="welcome-block">
    <div id="welcome">
      <div id="path">
        <router-link :to="{name: 'iaccueil'}">Accueil</router-link> > <router-link :to="{name: 'iactualities'}">Actualités</router-link>
      </div>
      <div id="title">
        Actualités
      </div>
    </div>
  </div> -->
  <!-- blogs list -->
  <div id="blogs-block">
    <div class="block-title">Actualités</div>
    <div id="blogs">
      <div class="blog" v-for="actuality in actualities" v-bind:key="actuality.id" @click="() => {linkHandler(actuality.id)}">
        <div class="image">
          <div class="img-back"></div>
          <img :src="actuImg(actuality.img)" alt="">
        </div>
        <div class="about">
          <div class="date">{{ actuality.createdAt }}</div>
          <div class="name">Admin</div>
        </div>
        <div class="title">
          {{ actuality.title }}
        </div>
        <div class="content-part" v-show="hasIntro(actuality)">
          {{ actuality.intro }}
        </div>
        <router-link :to="{name: 'iactuality', params: {id: actuality.id}, }">
          <div class="continue-btn">
            Afficher plus
            <font-awesome-icon :icon="['fas', 'arrow-right']" />
          </div>
        </router-link>
      </div>
    </div>
    <!-- <div id="new-pub">
      <router-link :to="{name: 'iactualitynew'}" >
        <font-awesome-icon :icon="['fas', 'add']" />
        Créer une publication
      </router-link>
    </div> -->
  </div>
</template>

<script>
import { actus } from '@/data/actualites';

export default {
  name: 'IActualitiesView',
  data() {
    return {
      // actualities: []
    }
  },
  computed: {
    actualities() {
      return actus;
    }
  },
  methods: {
    imageUrl(image) {
      return process.env.VUE_APP_URL +"/"+image;
    },
    scrollToTop() {
      const el = this.$refs.top;
      if(el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    },
    actuImg(img) {
      return require('@/assets/actus/'+img);
    },
    linkHandler(id) {
      this.$router.push({name: 'iactuality', params: {id: id}});
    },
    hasIntro(actu) {
      return actu.intro != "";
    }
  },
  components: {
    
  },
  mounted() {
    this.scrollToTop();
  }
}

</script>

<style lang="scss" scoped>
#top {
  margin-top: 50px;
}

#blogs-block {
  width: 90%;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;
  @media (min-width: 950px) {
    padding: 4rem;
    width: 70%;
  }
  #blogs {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    .blog {
      margin-bottom: 4rem;
      width: 100%;
      padding-bottom: 1rem;
      box-sizing: border-box;
      @media (min-width: 750px) {
        width: 45%;
      }
      &:hover {
        box-shadow: 0 0 1rem rgb(192, 192, 192);
        .title {
          color: #000000;
        }
        .image .img-back {
          background-color: #207dff17;
        }
      }
      .image {
        position: relative;
        z-index: 0;
        width: 100%;
        .img-back {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #c7c7c74d;
          transition: .3s ease all;
        }
        img {
          position: relative;
          z-index: 0;
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 15rem;
          border-radius: 0.2rem;
        }
      }
      .about {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
        color: gray;
        font-size: 0.8rem;
        .date {
          text-transform: uppercase;
        }
        .name {
          text-transform: uppercase;
          margin-left: 1rem;
        }
        .comment {
          margin-left: 1rem;
        }
      }
      .title {
        padding-top: 1rem;
        font-size: 1rem;
        color: #00194c;
      }
      .content-part {
        margin-top: 1rem;
        line-height: 1.5rem;
        height: 6rem;
        overflow-y: hidden;
        text-overflow: ellipsis;
        font-weight: 300;
        color: #535353;
        padding: 0 1rem;
        text-align: justify;
        box-sizing: border-box;
      }
      .continue-btn {
        margin-top: 1rem;
        display: inline-block;
        border: 1px solid #00194c;
        color: #00194c;
        border-radius: 0.2rem;
        padding: 0.5rem 1.2rem;
        font-size: 0.8rem;
        transition: .3s ease all;
        &:hover {
          background-color: #00194c;
          color: #fff;
        }
      }
    }
  }
  #new-pub {
    border: 1px solid #207dff;
    background-color: #207dff;
    border-radius: 0.2rem;
    padding: 0.7rem; 
    transition: 0.4s ease all;
    &:hover {
      background-color: #175dbe;
      box-shadow: .2rem .2rem 0.4rem #f6f6f6;
    }
    
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}
.block-title {
  width: 80%;
  margin: 0 auto;
  font-size: 2rem;
  color: #00194c;
  font-weight: 400;
}
</style>