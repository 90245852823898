<template>
  <i-nav-bar></i-nav-bar>
  <div id="master">
    <router-view :key="$route.fullPath"/>
  </div>
  <i-footBar></i-footBar>
</template>

<script>

import INavBar from '@/components/INavBar.vue'
import IFootBar from '@/components/IFootBar.vue'

export default {
  components: {
    INavBar,
    IFootBar,
  },
}

</script>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-y: overlay;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  box-sizing: border-box;
  background-color: rgb(219, 219, 219);
  // background-color: #fff;
  
  // overflow: hidden;
  // /* Style the scrollbar background */
  // ::-webkit-scrollbar {
  //   background: #ffffff13;
  // }
  
  // /* Style the scrollbar handle */
  // ::-webkit-scrollbar-thumb {
  //   background: #ffffff46;
  // }
}


#master {
  box-sizing: border-box;
  @media (min-width: 750px) {
    // padding-top: 8rem;
  }
}
</style>
