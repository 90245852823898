import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// google map
import VueGoogleMaps from '@fawmi/vue-google-maps'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


/* import specific icons */
// SOLID
import { 
  faBars, 
  faEnvelope, 
  faUsersViewfinder, 
  faTags, 
  faBarsProgress,
  faArrowsToDot,
  faMicrochip,
  faShield,
  faArrowsUpDownLeftRight,
  faShuffle,
  faHandshakeSimple,
  faCalendarDays,
  faCheckToSlot,
  faChevronLeft,
  faChevronRight,
  faQuoteLeft,
  faComment,
  faArrowRight,
  faMobileScreen,
  faUser,
  faAdd,
  faCircleNotch,
  faPeopleGroup,
  faPuzzlePiece,
  faWeightScale,
  faArrowsToCircle,
  faLocationDot
} from '@fortawesome/free-solid-svg-icons'

// BRAND
import { 
  faYoutube, 
  faInstagram, 
  faTwitter, 
  faFacebookF,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons'

// REGULAR
import { 
  faChartBar,
  faMap,
  faEnvelope as faEnvelopeR,
} from '@fortawesome/free-regular-svg-icons'


/* add icons to the library */
library.add(
  faBars, 
  faYoutube, 
  faInstagram, 
  faFacebookF, 
  faTwitter, 
  faEnvelope, 
  faUsersViewfinder, 
  faTags, 
  faBarsProgress,
  faArrowsToDot,
  faMicrochip,
  faShield,
  faArrowsUpDownLeftRight,
  faShuffle,
  faChartBar,
  faHandshakeSimple,
  faCalendarDays,
  faCheckToSlot,
  faChevronLeft,
  faChevronRight,
  faQuoteLeft,
  faComment,
  faArrowRight,
  faMap,
  faMobileScreen,
  faEnvelopeR,
  faUser,
  faAdd,
  faCircleNotch,
  faPeopleGroup,
  faPuzzlePiece,
  faWeightScale,
  faArrowsToCircle,
  faLinkedin,
  faLocationDot
  )

createApp(App).use(router).use(VueGoogleMaps, {
  load: {
      key: 'YOUR_API_KEY_COMES_HERE',
  },
}).component('font-awesome-icon', FontAwesomeIcon).mount('#app')