<template>
  <div id="top" ref="top"></div>
  <div id="pages">
    <div id="apropos-block">
      <div class="block-title">A propos de nous</div>
      <div id="apropos">
        <div id="content">
          NSI Mada vous accompagne dans vos démarches d’externalisation, dans la réalisation de vos projets à court, à moyen et à long terme. Composée de jeunes passionnés, les collaborateurs interviennent dans plusieurs domaines, multitâches et disposant des années d’expériences. Les managers vous accompagnent avec des équipes proactives, répondant aux attentes des clients dans la réalisation de leurs objectifs.
          <router-link :to="{name: 'itravailleravecnous'}" id="devis-btn">Travailler avec nous</router-link>
        </div>
        <div id="image">
          <div id="img-back"></div>
          <img src="@/assets/imgpsh_fullsize_anim.jpeg" alt="">
        </div>
      </div>
    </div>
    <div id="nos-expertises">
      <div class="block-title">Notre services</div>
      <div id="cards">
        <div class="card-block">
          <div class="card">
            <!-- image -->
            <div class="img">
              <img src="@/assets/téléprosp.png" alt="">
            </div>
            <!-- title -->
            <div class="title">
              Téléprospection
            </div>
            <!-- content -->
            <div class="content">
              Notre société propose des services de téléprospection de qualité pour aider les entreprises à atteindre de nouveaux clients potentiels et à générer des leads précieux. Grâce à notre équipe expérimentée et à nos stratégies éprouvées, nous visons à stimuler vos ventes et à élargir votre base de clients.
            </div>
          </div>
        </div>
        <div class="card-block">
          <div class="card">
            <!-- image -->
            <div class="img">
              <img src="@/assets/marketing.png" alt="">
            </div>
            <!-- title -->
            <div class="title">
              Etudes Marketing
            </div>
            <!-- content -->
            <div class="content">
              NSI vous aide à prendre des décisions grâce à notre département Etudes Marketing. Nous vous assistons pour collecter les informations les plus pertinentes pour mieux connaître vos clients et attirer plus de prospects.
            </div>
          </div>
        </div>
        <div class="card-block">
          <div class="card">
            <!-- image -->
            <div class="img">
              <img src="@/assets/clients.jpg" alt="">
            </div>
            <!-- title -->
            <div class="title">
              Acquisition de Clients
            </div>
            <!-- content -->
            <div class="content">
              NSI propose des services d'acquisition de clients de premier plan, en utilisant des techniques avancées de prospection et de gestion des leads pour vous aider à développer votre base de clients. Avec notre expertise et notre approche personnalisée, nous sommes déterminés à vous aider à atteindre vos objectifs de croissance commerciale. (Interactions téléphoniques et digitales (click to call, email, sms / click-to-text, click-to-chat...etc.)
            </div>
          </div>
        </div>
        <div class="card-block">
          <div class="card">
            <!-- image -->
            <div class="img">
              <img src="@/assets/clavs.jpg" alt="">
            </div>
            <!-- title -->
            <div class="title">
              Réception d’appels 
            </div>
            <!-- content -->
            <div class="content">
              Nous créons une expérience inoubliable pour vos clients et prospects, ce qui vous permet de développer des relations solides, de vous distinguer de vos concurrents et de démontrer votre engagement envers leur satisfaction, garantissant ainsi la sécurité de votre portefeuille client.
            </div>
          </div>
        </div>
        <div class="card-block">
          <div class="card">
            <!-- image -->
            <div class="img">
              <img src="@/assets/crm.jpg" alt="">
            </div>
            <!-- title -->
            <div class="title">
              Gestion de la Relation Client 
            </div>
            <!-- content -->
            <div class="content">
              Notre compétence en matière d'externalisation de la Relation Client, associée à notre capacité à intégrer les dernières technologies, nous permet de fournir une expérience client exceptionnelle à chaque point de contact, que ce soit par téléphone ou via les canaux numériques, tout en stimulant constamment la fidélité et la valeur de votre clientèle.
            </div>
          </div>
        </div>
        <div class="card-block">
          <div class="card">
            <!-- image -->
            <div class="img">
              <img src="@/assets/telesecretariat-medical.jpg" alt="">
            </div>
            <!-- title -->
            <div class="title">
              Télésecrétariat médicale 
            </div>
            <!-- content -->
            <div class="content">
              Un dispositif de ressources confirmées s’intégrant parfaitement dans votre organisation pour traiter les demandes de vos patients :
              <ul>
                <li>Prise et gestion des rendez-vous médicaux</li>
                <li>Assistance personnalisée de la patientèle</li>
                <li>Gestion d’agenda des médecins</li>
                <li>Vente</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-block">
          <div class="card">
            <!-- image -->
            <div class="img">
              <img src="@/assets/data.jpg" alt="">
            </div>
            <!-- title -->
            <div class="title">
              Traitement de données 
            </div>
            <!-- content -->
            <div class="content">
              Des opérateurs polyvalents, exploitant à la fois l’IA et leur expertise humaine pour garantir la qualité et l’efficacité :
              <ul>
                <li>
                  Saisie et vérification de données
                </li>
                <li>
                  Retranscription de données
                </li>
                <li>
                  Contrôle manuel de documents en gros volumes
                </li>
                <li>
                  Saisie Comptable, etc.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-block">
          <div class="card">
            <!-- image -->
            <div class="img">
              <img src="@/assets/market.jpg" alt="">
            </div>
            <!-- title -->
            <div class="title">
              Marketing  digital 
            </div>
            <!-- content -->
            <div class="content">
              Nous vous accompagnons dans : 
              <ul>
                <li>Référencement </li>
                <li>SEO/SEA/Ads</li>
                <li>Réseaux sociaux</li>
                <li>UX/UI design</li>
                <li>Vidéo motion design</li>
                <li>Naming et Branding</li>
                <li>Audit et conseil</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-block">
          <div class="card">
            <!-- image -->
            <div class="img">
              <img src="@/assets/compta.jpg" alt="">
            </div>
            <!-- title -->
            <div class="title">
              Notre centre de compétences: COMPTABILITE 
            </div>
            <!-- content -->
            <div class="content">
              Nous vous accompagnons dans :
              <ul>
                <li>La conception</li>
                <li>Le développement </li>
                <li>Saisie et vérification de données</li>
                <li>Retranscription de données</li>
                <li>Contrôle manuel de documents en gros volumes</li>
                <li>Gestion Comptable</li>
                <li>Gestion de la Paie</li>
                <li>Rapports Financiers </li>
                <li>Suivi des Stocks</li>
                <li>Intégration Bancaire</li>
                <li>Facturation et Gestion des Clients</li>
                <li>Et bien d’autres encore </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'INosExpertisesView',
  methods: {
    scrollToTop() {
      const el = this.$refs.top;
      if(el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    }
  },
  mounted() {
    this.scrollToTop();
  }
}

</script>

<style lang="scss" scoped>
#top {
  margin-top: 50px;
}
#apropos-block {
  padding: 2rem 0;
  margin: 0 auto;
  width: 90%;
  box-sizing: border-box;
  @media (min-width: 750px) {
    width: 80%;
  }
  @media (min-width: 950px) {
    padding: 5rem 0;
    width: 70%;
  }
  #apropos {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap-reverse;
    width: 100%;
    margin-top: 2rem;
    // background-color: rgb(247, 247, 247);
    #content {
      padding-top: 1rem;
      text-align: justify;
      font-weight: 400;
      line-height: 1.7rem;
      font-size: 1.1rem;
      margin: auto 0;
      width: 100%;
      @media (min-width: 950px) {
        // width: 47%;
      }
      #devis-btn {
        margin-top: 1rem;
        font-weight: normal;
        display: block;
        color: #00194c;
        padding: 0.7rem 1.7rem;
        transition: .5s ease all;
        text-align: center;
        border-radius: 0.3rem;
        border: 1px solid #00194c;
        &:hover {
          background-color: #00194c;
          color: #fff;
        }
      }
      a {
        text-decoration: none;
      }
    }
    #image {
      width: 100%;
      height: 100%;
      position: relative;
      @media (min-width: 950px) {
        // width: 47%;
        height: 100%;
      }
      #img-back {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color: #00194cbb;
        background: radial-gradient(#00194c2a, #00194c);
        position: absolute;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
#nos-expertises {
  padding-top: 2rem;
  width: 100%;
  box-sizing: border-box;
  #cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    .card-block {
      width: 100%;
      box-sizing: border-box;
      margin: 2rem auto;
      padding: 1rem;
      @media (min-width: 750px) {
        width: 45%;
      }
      @media (min-width: 950px) {
        width: 30%;
      }
      .card {
        .img {
          overflow: hidden;
          border-radius: 0.3rem;
          img {
            width: 100%;
            height: 15rem;
            object-fit: cover;
            object-position: center;
            transition: .4s ease all;
          }
        }
        .title {
          font-size: 1.3rem;
          color: #00194c;
          margin-top: 1rem;
        }
        .content {
          margin-top: 1rem;
          text-align: start;
          font-weight: 400;
          line-height: 1.4rem;
        }
      }
      &:hover > .card > .img > img {
        transform: scale(1.2);
      }
    }
  }
}
.block-title {
  width: 80%;
  margin: 0 auto;
  font-size: 2rem;
  color: #00194c;
  font-weight: 400;
}
</style>