<template>
  <div id="foot-bar">
    <!-- LOGO NSI -->
    <div id="logo">
      <img src="@/assets/logo.png" alt="">
    </div>
    <!-- CONTACT -->
    <div id="contact">
      <div class="title">Contactez-nous</div>
      <ul>
        <li>+261 32 77 013 64</li>
        <li>+261 32 78 455 62</li>
        <li>
          contact@nsi-mada.com
        </li>
      </ul>
    </div>
    <!--LINKED IN-->
    <div id="linkedin">
      <div class="title">Liens</div>
      <ul>
        <li>
          <a href="https://www.linkedin.com/company/nsi-mada/" target="_blank">
            <font-awesome-icon :icon="['fab', 'linkedin']" />
            NSI MADA
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/nsimada.call" target="_blank">
            <font-awesome-icon :icon="['fab', 'facebook-f']" />
            NSi.mada
          </a>
        </li>
      </ul>
    </div>
    <!-- SEPARATEUR -->
    <div id="separator"></div>
    <div id="copyright">
      <font-awesome-icon :icon="['fas', 'location-dot']" />
      F II 54 bis Ambohimahintsy Tana 103
    </div>
  </div>
</template>

<script>

export default {
  name: 'IFootBar'
}

</script>

<style lang="scss" scoped>
#foot-bar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  padding-bottom: 1rem;
  background-color: #00194c;
  // background-color: rgb(36, 36, 36);
  width: 100%;
  box-sizing: border-box;
  @media (min-width: 750px) {
    flex-direction: row;
    align-items: start;
  }

  & > div {
    // border: 1px solid white;
    width: 100%;
    text-align: start;
    margin: 0.4rem 0;

    @media (min-width: 750px) {
      margin: 0;
      width: 30%;
    }

    .title {
      color: white;
      // color: rgb(0, 0, 156);
      font-size: 1.2rem;
      font-weight: bold;
    }

    ul {
      padding: 0;
      margin: 0;
    }
    li {
      list-style: none;
      color: #ffffff;
      // color: blue;
      font-weight: 300;
      margin: 0.5rem 0;

      a {
        text-decoration: none;
        color: #fff;
      }
    }
  }
  #logo {
    width: 20rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  #separator {
    width: 100%;
    border-top: 1px solid rgb(224, 224, 224, .3);
    margin: 1rem 0;
  }
  #links {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
    border: 1px solid #fff;
    .link {
      width: 1.8rem;
      height: 1.8rem;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: rgb(20, 20, 20);
      border-radius: 50%;
      margin: 0 0.3rem;
      border: 1px solid #fff;

      a {
        color: white;
      }
    }
  }
  #follow {
    width: 100%;
    text-align: center;
    color: rgb(199, 199, 199);
    margin-top: 0.6rem;
  }

  #copyright {
    width: 100%;
    text-align: center;
    color: #fff;
    size: 0.8rem;
    font-weight: 300;
  }
}
</style>